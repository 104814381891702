import gql from 'graphql-tag'
import {omitBy, includes, reject} from 'lodash'
import {withdrawalPaymentFields, depositStatuses, appropTestQuestionsV2,
  dueDiligenceQuestions, suitabilityTestQuestions} from '@bdswiss/common-enums'

const PAYMENT_FIELDS = omitBy(withdrawalPaymentFields,
  (f) => includes(['amount', 'withdrawalReason', 'withdrawalReasonText'], f.key) ||
  f.isInternal ||
  (f.visible && !f.visible())
)

const VISIBLE_DEPOSIT_STATUSES = reject(Object.keys(depositStatuses), (s) => s === 'pending').join()

export const CLIENT_BASIC_INFO_FIELDS = `
  firstName
  lastName
  birthday
  nationality
  phone
`

export const CLIENT_MIFIR_INFO_FIELDS = `
  mifirId
  mifirType
`

export const SECONDARY_PHONES_QUERY = `
  secondaryPhones {
    secondaryPhone1
  }
`

export const COPY_TRADING_FIELDS = `
  id
  createdAt
  updatedAt
  startRequestedDate
  stopRequestedDate
  startCopyingDate
  stopCopyingDate
  isActive
  account {
    __typename
    ... on ForexAccount {
      id
    }
  }
  accountCopying {
    accountId
    remoteId
    accountName
  }
  deletedAt
`

export const SECONDARY_EMAILS_QUERY = `
  secondaryEmails {
    secondaryEmail1
    secondaryEmail2
    secondaryEmail3
  }
`

export const CORRESPONDENCE_ADDRESS_QUERY = `
  correspondenceAddress {
    line1
    houseNumber
    city
    zip
    region
    country
  }
`

export const ADDRESS_QUERY = `
  address {
    line1
    houseNumber
    city
    zip
    region
    country
  }
`

export const GLOBAL_QUESTIONNAIRE_QUERY = `
  globalQuestionnaire {
    transactionPurpose
    approxExpectedDeposit
    originOfFunds
    natureOfTransactions
    approxNetWorth
    approxYearlyIncome
    sourceOfFunds
    jobTitle
    politicallyExposed
    usCitizen
    taxJurisdictionCountry
    tin
    tinReason
    transactionPurposeClarify
    natureOfTransactionsClarify
    sourceOfFundsClarify
    tinClarify
    politicallyExposedReason
  }
`

export const PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT = gql`
  fragment PaymentCardTokenFields on PaymentCardToken {
    id
    brand
    lastFour
    expiryMonth
    expiryYear
    vendor
    meta
  }
`

export const AI_POSITIONS_FRAGMENT = gql`
  fragment AIAccountFields on BaseForexAccount {
    margin
    equity
    forexPositionsCount(tradingPositionStatus: all)
    forexPositions(tradingPositionStatus: all limit: 5000 offset: 0) {
      ticket
      symbol
      command
      volume
      openTime
      openRate
      closeTime
      closeRate
      profit
      stopLoss
      takeProfit
      commission
      swaps
    }
  }
`

export const CLIENT_BASIC_INFO_FRAGMENT = gql`
  fragment ClientBasicInfo on Client {
    ${CLIENT_BASIC_INFO_FIELDS}
    nickname
    previousNames
    passportNumber
  }
`

export const CLIENT_MIFIR_INFO_FRAGMENT = gql`
  fragment MifirInfo on Client {
    ${CLIENT_MIFIR_INFO_FIELDS}
  }
`

export const SETTINGS_FIELDS_FRAGMENT = gql`
  fragment ClientSettingsFields on Client {
    minimumDeposits {
      currency
      amount
    }
    registrationCampaigns {
      affiliateId
      campaign {
        redirectWebTraderFtd
        type
      }
    }
    optInMarketing
    optInSms
    canTrade
    allowTransfers
    autogeneratedPassword
    canGrantSpoa
    hasPortfolioManagement
    signupStep
    canEditLeverage
    allowNewAccounts
    hasCampaign
    emailConfirmed
    emailPendingChange
    hasSpotOptionCFD
    isEmailVerificationRequired
  }
`

export const SPOA_FIELDS_FRAGMENT = gql`
  fragment SpoaFields on Client {
    spoaRemoteId
    spoaRemoteClient {
      id
      firstName
      lastName
      accounts{
        __typename
        ... on BaseAccount {
          currency
          id
          remoteId
          accountName
        }
     }
    }
  }
`

export const SECONDARY_PHONES_FRAGMENT = gql`
  fragment SecondaryPhones on Client {
    ${SECONDARY_PHONES_QUERY}
  }
`

export const SECONDARY_EMAILS_FRAGMENT = gql`
  fragment SecondaryEmails on Client {
    ${SECONDARY_EMAILS_QUERY}
  }
`

export const CLIENT_ADDRESS_FRAGMENT = gql`
  fragment Address on Client {
    ${ADDRESS_QUERY}
  }
`

export const CLIENT_CORRESPONDENCE_ADDRESS_FRAGMENT = gql`
  fragment CorrespondenceAddress on Client {
    ${CORRESPONDENCE_ADDRESS_QUERY}
  }
`

export const GLOBAL_QUESTIONNAIRE_FRAGMENT = gql`
  fragment GlobalQuestionnaire on Client {
   ${GLOBAL_QUESTIONNAIRE_QUERY}
  }
`

export const TRADING_STATUS_FRAGMENT = gql`
  fragment TradingStatusInfo on Client {
    tradingStatusReasonCode
    tradingStatusReason
    tradingStatus
  }
`

export const KYC_STATUS_FRAGMENT = gql`
  fragment KYCStatusInfo on Client {
    kycStatus
    kycStatusReasonCode
    kycStatusReason
  }
`

export const ELECTRONIC_ID_VERIFICATION_FRAGMENT = gql`
  fragment ElectronicIDVerification on Client {
    eIdVerificationResults {
      id
      identityReliability
      rawResult
      createdAt
    }
    eIdVerificationApplicable
  }
`

export const DEPOSIT_FIELDS_FRAGMENT = gql `
  fragment DepositFields on Deposit {
    currency
    id
    status
    amount
    createdAt
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    payment {
      vendor
      meta
      details
      transferFromAccount {
        __typename,
        ... on BaseAccount {
          id
          remoteId
        }
      }
    }
  }
`

export const SUBSCRIPTIONS_FIELDS_FRAGMENT = gql `
  fragment SubscriptionFields on Subscription {
    currency
    id
    status
    amount
    createdAt
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    payment {
      vendor
      meta
      transferFromAccount {
        __typename,
        ... on BaseAccount {
          id
          remoteId
        }
      }
    }
  }
`

export const WITHDRAWAL_FIELDS_FRAGMENT = gql`
  fragment WithdrawalFields on Withdrawal {
    id
    status
    rejectionReasonCode
    createdAt
    type
    currency
    amount
    withdrawalReason
    withdrawalReasonText
    paymentVendor
    queuePosition
    paymentFields {
      ${Object.keys(PAYMENT_FIELDS).join(' ')}
      goldValue
    }
    transferToAccount {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
  }
`

export const ACCOUNTS_FIELDS_FRAGMENT = gql `
  ${AI_POSITIONS_FRAGMENT}
  fragment AccountFields on Account {
    ... on BaseAccount {
      id
      __typename
      createdAt
      currency
      balance
      login
      remoteId
      mobile
      hidden
      outstandingActivationDeposit
      isDemo
      hasHeldDeposits
      bonusOffers(status: [userApproved, autoApproved], orderBy: createdAt, orderDirection: descending) {
        id
        type
        status
        currency
        amount
        createdAt
      }
      availableWithdrawalOptions
      balance
      minimumDeposit
      copyTrading {
        ${COPY_TRADING_FIELDS}
      }
      copyTradingCancelledRequests{
        ${COPY_TRADING_FIELDS}
      }
      accountName
      passwordProtectedStrategies
      metadata
    }
    ... on BaseOptionAccount {
      spotOptionId
    }
    ... on BaseForexAccount {
      leverage
      accountSubtype
      isReadOnly
      isViewOnly
      mobile
      allowedLeverages
      isArchived
      isManaged
      marginInfo {
        balance
      }
      server
      metaTraderApiUrl
      copyTrading {
        ${COPY_TRADING_FIELDS}
      }
      copyTradingCancelledRequests{
        ${COPY_TRADING_FIELDS}
      }
      competitions {
        startDate
        exitDate
        active
        competitionDetails {
          id
          rules
          name
          prizes
          icon
          termsLink
        }
      }
      credit
      provideCopyTrading
      performanceFee
      showProviderStats
      provideCopyTradingStartDate
    }
    ... on BaseTradesmarterAccount {
      withdrawableBalance
      isViewOnly
    }
    ... on BaseCryptoCoinAccount {
      coins
      walletAddress
      depositWalletAddress
    }
    ... on BaseGoldAccount {
      gold
      subscriptions {
        id
        state
        amountCent
        planName
        activationDate
        lastBillingDate
        nextBillingDate
      }
    }
    ... on AIPAMMAccount {
      ...AIAccountFields
    }
    ... on AIPAMMMauritiusAccount {
      ...AIAccountFields
    }
    ... on AIPAMMDemoAccount {
      ...AIAccountFields
    }
    ... on AIPAMMMauritiusDemoAccount {
      ...AIAccountFields
    }
    ... on BaseAffiliateAccount {
      serviceFields {
        affiliateStatus
      }
    }
    ... on BaseCryptoWalletAccount {
      cryptoBalance
      cryptoTransactions {
        id
        type
        amount
        currency
        status
        destinationWallet
        createdAt
      }
    }
    ... on BaseFiatWalletAccount {
      balance
    }
    ... on BaseSMPrimeWalletAccount {
      margin
      freeMargin
      equity
    }
    ... on BaseForexRawAccount {
      volumeTraded
      isInFallback
      subscription {
        id
        plan
        nextPaymentDate
        cancelledAt
        isActive
        isExpired
        pendingPlan
        lastPaymentFailed
        billingInfo {
          fullName
          address1
          zip
          cardType
          month
          year
          lastFourDigits
        }
      }
    }
    ... on BaseIntroducingBrokerAccount {
      approved
      parentIbId
      ibId
      ignoreIbTransfers
    }
    ... on BasePammAccount {
      approved
    }
    ... on BaseBitnukWalletAccount {
      walletAddress
    }
    ...on PAMMTauroMarketsManagerAccount {
      isViewOnly
    }
    ...on PAMMTauroMarketsMauritiusManagerAccount {
      isViewOnly
    }
  }
`

export const DOCUMENT_FIELDS_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    type
    status
    createdAt
    rejectionReasonCode
    rejectionReason
  }
`

export const PROFILE_CHANGES_ANSWERS_FRAGEMENT = gql`
  fragment ProfileChangeAnswerFields on ProfileChangeAnswers {
    ${CLIENT_BASIC_INFO_FIELDS}
    ${CLIENT_MIFIR_INFO_FIELDS}
    ${ADDRESS_QUERY}
    ${GLOBAL_QUESTIONNAIRE_QUERY}
    ${SECONDARY_PHONES_QUERY}
    ${SECONDARY_EMAILS_QUERY}
  }
`

export const APPROP_TEST_FIELDS_FRAGMENT = gql`
  fragment AppropTestFields on AppropTest {
    status
    rejectionReasonCode
    createdAt
    forexPoints
    scoreResult
    answers {
      ${Object.keys(appropTestQuestionsV2).join(' ')}
    }
  }
`
export const DUE_DILIGENCE_FIELDS_FRAGMENT = gql`
  fragment DueDiligenceFields on DueDiligence {
    status
    createdAt
    answers {
      ${Object.keys(dueDiligenceQuestions).join(' ')}
    }
  }
`

export const SUITABILITY_TEST_FIELDS_FRAGMENT = gql`
  fragment SuitabilityTestFields on SuitabilityTest {
    status
    createdAt
    answers {
      ${Object.keys(suitabilityTestQuestions).join(' ')}
    }
    scoreResult
    portfolioPoints
  }
`

export const CLIENT_DATA_QUERY = gql `
  ${CLIENT_ADDRESS_FRAGMENT}
  ${SECONDARY_PHONES_FRAGMENT}
  ${SECONDARY_EMAILS_FRAGMENT}
  ${CLIENT_BASIC_INFO_FRAGMENT}
  ${CLIENT_MIFIR_INFO_FRAGMENT}
  ${KYC_STATUS_FRAGMENT}
  ${ELECTRONIC_ID_VERIFICATION_FRAGMENT}
  ${TRADING_STATUS_FRAGMENT}
  ${SETTINGS_FIELDS_FRAGMENT}
  ${APPROP_TEST_FIELDS_FRAGMENT}
  ${DUE_DILIGENCE_FIELDS_FRAGMENT}
  ${SUITABILITY_TEST_FIELDS_FRAGMENT}
  ${GLOBAL_QUESTIONNAIRE_FRAGMENT}
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${CLIENT_CORRESPONDENCE_ADDRESS_FRAGMENT}
  query Viewer {
    viewer {
      __typename
      ... on Client {
        ...ClientBasicInfo
        ...ClientSettingsFields
        ...SecondaryPhones
        ...SecondaryEmails
        ...Address
        ...MifirInfo
        ...TradingStatusInfo
        ...KYCStatusInfo
        ...ElectronicIDVerification
        ...CorrespondenceAddress
        id
        email
        oauthId
        externalVerificationUrl
        locale
        themePreference
        clientType
        company
        whiteLabel
        depositedAmount
        registration
        ftdDate
        missingDocuments
        missingEP
        metadata
        pendingUploadDocuments {
          poi {
            allowUpload
            status
          }
          por {
            allowUpload
            status
          }
          partner {
            allowUpload
            status

          }
          personal {
            allowUpload
            status
          }
        }
        pendingNoticesCount
        bdxWaiverAccepted
        underMonitoring
        underMonitoringReasonCode
        kycStatus
        kycStatusReason
        kycStatusReasonCode
        autoChartistEnabled
        tradingCentralEnabled
        isPhoneVerified
        isPhoneVerificationRequired
        canProcessAppropTest
        vps
        vpsSubscriptions {
          id
          status
          isActive
        }
        autoChartistEmails
        website
        skypeId
        affiliateCountries{
          countriesPromoted
        }
        signableNoticeAckSignatures{
          noticeId
          signature
          dateSigned
        }
        appropTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...AppropTestFields
        }
        suitabilityTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...SuitabilityTestFields
        }
        dueDiligences(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...DueDiligenceFields
        }
        paymentCardTokens {
          id
          lastFour
          brand
          vendor
          meta
        }
        ...GlobalQuestionnaire
        documents(orderBy: createdAt, orderDirection: descending) {
          ...DocumentFields
        }
        verificationActions {
          forcedVerification
          blockDeposit
        }
        pendingNotices {
          id
          version
          validFromDate
          type
          title
          content
          createdAt
          localizationKey
          noticePeriod
          isContentHtml
          acknowledgements {
            acknowledgementType
          }
        }
        pendingMessages {
          id
          type
          metadata
        }
        paymentMethods {
          id
          details
          fundingCategory
          confirmed
          relatedDocumentIds
          pendingUpload{
            allowUpload
            status
          }
        }
        competitions {
          id
          name
          startDate
          endDate
          rules
          prizes
          icon
          termsLink
          participantsCount
        }
        allowedAccountTypes
        clientAllowCopyTrading
        eligibleBonus {
          eligibleBonus
          daysLeft
          percentage
          maxAmount
          daysRemainingNotification
        }
        manuallyAllowedAccounts
        pendingLoginVerification
        swapFree
        allowSwapFree
        hasPartnerAccount
        acceptedTermsSpoaBonus
        tradeCompanionEnabled
        euMigration
        tradeCompanionNotificationClose
        tradeCompanionTableauUser
        fromCompany
        multipleRegulation
        acceptEuMigrationTerms
        showPasswordProtectedStrategies
        bonusTerms
        hasTradingAcademyAccess
        nonDepositorUser
        fundManager
        affiliateHasApprovedPAMM
        affiliatePAMMCurrency
      }
    }
  }
`

export const CLIENT_PROFILE_QUERY = gql `
  ${SECONDARY_PHONES_FRAGMENT}
  ${SECONDARY_EMAILS_FRAGMENT}
  ${CLIENT_BASIC_INFO_FRAGMENT}
  ${CLIENT_ADDRESS_FRAGMENT}
  ${CLIENT_CORRESPONDENCE_ADDRESS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        ...ClientBasicInfo
        ...SecondaryPhones
        ...SecondaryEmails
        ...Address
        ...CorrespondenceAddress
        email
        emailPendingChange
      }
    }
  }
`

export const PROFILE_SETTINGS_QUERY = gql`
  ${GLOBAL_QUESTIONNAIRE_FRAGMENT}
  ${APPROP_TEST_FIELDS_FRAGMENT}
  ${DUE_DILIGENCE_FIELDS_FRAGMENT}
  ${SUITABILITY_TEST_FIELDS_FRAGMENT}
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${PROFILE_CHANGES_ANSWERS_FRAGEMENT}
  query {
    viewer {
      ... on Client {
        canGrantSpoa
        hasPortfolioManagement
        themePreference
        ...GlobalQuestionnaire
        appropTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...AppropTestFields
        }
        profileChanges(orderBy: createdAt, orderDirection: descending, limit: 1, status: pending) {
          answers {
            ...ProfileChangeAnswerFields
          }
        }
        dueDiligences {
          ...DueDiligenceFields
        }
        suitabilityTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...SuitabilityTestFields
        }
        documents(orderBy: createdAt, orderDirection: descending) {
          ...DocumentFields
        }
        verificationActions {
          forcedVerification
          blockDeposit
        }
      }
    }
  }
`

export const ACCOUNTS_QUERY = gql`
  ${ACCOUNTS_FIELDS_FRAGMENT}
  query Accounts($id: Int){
    viewer {
      ... on Client {
        accounts(orderBy: createdAt, hidden: false, id: $id, rejected: false) {
          ...AccountFields
          ... on PAMMTauroMarketsAccount {
            unconnected
          }
          ... on PAMMTauroMarketsMauritiusAccount {
            unconnected
          }
        }
      }
    }
  }
`

export const WITHDRAWABLE_METHODS_QUERY = gql`
  query ($id: Int){
    viewer {
      ... on Client {
        accounts(id: $id) {
          ... on BaseAccount {
            withdrawablePaymentMethods {
              id
              order
              details
              vendor
              cardNumber
              vendorAccountEmail
              vendorAccountId
              iban
              swiftCode
              cryptoCurrency
              amount
              currency
              amountLeft
              bankCode
              accountNumber
              provider
              paymentOption
            }
          }
        }
      }
    }
  }
`

export const PAYMENTS_ACCOUNTS_QUERY = gql`
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        canIbTransfer
        accounts(orderBy: createdAt, hidden: false) {
          __typename
          ... on BaseAccount {
            id
            currency
            balance
            isDemo
            hidden
            remoteId
            availableWithdrawalOptions
            withdrawals(orderBy: createdAt) {
              ...WithdrawalFields
            }
            minimumDeposit
            accountName
            metadata
          }
          ... on BaseForexAccount {
            isArchived
            isViewOnly
            accountSubtype
            isReadOnly
            freeMargin
            withdrawalsBlocked
            copyTrading {
              id
              isActive
              startRequestedDate
              stopRequestedDate
              accountCopying {
                accountId
                remoteId
                accountName
              }
            }
            eligibleBonus
            credit
            hasOpenPositions
          }
          ... on BaseForexPremiumAccount {
            leverageChangedBasedOnEquity
            marginInfo {
              equity
            }
          }
          ... on BaseForexRawAccount {
            subscription {
              id
              plan
              nextPaymentDate
              cancelledAt
              isActive
              isExpired
              pendingPlan
              lastPaymentFailed
            }
          }
          ... on BaseIntroducingBrokerAccount {
            ignoreIbTransfers
          }
          ... on BaseTradesmarterAccount {
            withdrawableBalance
            isViewOnly
          }
          ... on BaseBitnukWalletAccount {
            withdrawalFee
          }
          ... on PAMMTauroMarketsAccount {
            unconnected
          }
          ... on PAMMTauroMarketsMauritiusAccount {
            unconnected
          }
          ... on PAMMTauroMarketsManagerAccount {
            isViewOnly
          }
          ...on PAMMTauroMarketsMauritiusManagerAccount {
            isViewOnly
          }
        }
      }
    }
  }
`

export const PAYMENTS_HISTORY_QUERY = gql`
  ${DEPOSIT_FIELDS_FRAGMENT}
  ${SUBSCRIPTIONS_FIELDS_FRAGMENT}
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  ${PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        accounts(orderBy: createdAt, hidden: false) {
          ... on BaseAccount {
            id
            currency
            balance
            isDemo
            hidden
            withdrawals(orderBy: createdAt) {
            ...WithdrawalFields
            }
            deposits(status: [${VISIBLE_DEPOSIT_STATUSES}], orderBy: createdAt, includeSubscriptions:false) {
              ...DepositFields
            }
            subscriptionDeposits {
              ...SubscriptionFields
            }
          }
          ... on BaseForexAccount {
            accountSubtype
          }
        }
        paymentCardTokens {
          ...PaymentCardTokenFields
        }
      }
    }
  }
`

export const PAYMENT_CARD_TOKEN_QUERY = gql`
  ${PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        paymentCardTokens {
          ...PaymentCardTokenFields
        }
      }
    }
  }
`

export const DLOCAL_BANKS_QUERY = gql`
  query {
    viewer {
      ... on Client {
          dlocalBanksList{
          code
          name
          logo
          paymentType
        }
      }
    }
  }
`

export const FUNTOPAY_WITHDRAWAL_BANKS_QUERY = gql`
  query {
    funtopayWithdrawalBanks {
      banks
    }
  }
`

export const COUNTRY_PAYMENT_OPTIONS_QUERY = gql`
  query {
    countryPaymentOptions {
      id
      country
      paymentOption {
        id
        name
        provider
        logoUrl
        localizationKey
        paymentKey
        additionalFields
        clientAddressRequired
        bankDetails {
          clientAccountCurrency
          recipient
          iban
          creditingAccount
          swift
          bank
          address
          city
          country
          currency
          reference
        }
      }
      enabled
      rank
      popular
    }
  }
`

export const PENDING_NOTICES_QUERY = gql`
  {
    viewer {
      ... on Client {
        pendingNotices {
          id
          version
          validFromDate
          type
          title
          content
          createdAt
          localizationKey
          noticePeriod
          isContentHtml
          acknowledgements {
            acknowledgementType
          }
        }
      }
    }
  }
`

export const DISPLAY_BANNER_QUERY = gql`
  query($id:String!, $type:String!) {
    banner: bannerDisplay(locale: $locale, type: $type) {
      file
      link
    }
  }
`

export const MARKED_UP_RATE_QUERY = gql`
  query ($fromCurrency: String!, $toCurrency: String!) {
    getMarkedUpRate(fromCurrency: $fromCurrency, toCurrency: $toCurrency)
  }
`

export const IB_CLIENTS_QUERY = gql`
  query {
    getIbClients {
      id
      firstName
      lastName
      accounts {
        __typename
        ... on BaseAccount {
          id
          remoteId
          currency
          accountName
        }
      }
    }
  }
`

export const RELATED_IB_QUERY = gql`
  query {
    getRelatedIbs {
      clientId: id
      firstName
      lastName
      parentIbId
      id: ibId
      accountId
    }
  }
`

export const IB_CLIENT_ACCOUNTS_QUERY = gql`
  query GetIbClienAccounts($clientId: Int!) {
    getIbClientAccounts(clientId: $clientId) {
      __typename
      ... on BaseAccount{
        id
        remoteId
        currency
        accountName
      }
    }
  }
`

export const FOREX_ACCOUNT_QUERY = gql`
  query forexAcount($id: Int) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseAccount {
            id
            login
            currency
            hasHeldDeposits
          }
          ...on BaseForexAccount {
            credit
            outstandingActivationDeposit
            isArchived
            isDemo
            isReadOnly
            isViewOnly
            balance
            server
            accountSubtype
            metaTraderApiUrl
            copyTrading {
              ${COPY_TRADING_FIELDS}
            }
            copyTradingCancelledRequests{
              ${COPY_TRADING_FIELDS}
            }
            marginInfo {
              freeMargin
              equity
              level
            }
            bonus {
              amount
              requiredLots
              lotsTraded
            }
          }
          ... on BaseForexRawAccount {
            volumeTraded
            isInFallback
            isInOverTheLimit
            subscription {
              id
              plan
              volumeToTrade
              nextPaymentDate
              isActive
              isExpired
              pendingPlan
              createdAt
              updatedAt
              deletedAt
              cancelledAt
              lastPaymentFailed
              billingInfo {
                fullName
                address1
                zip
                cardType
                month
                year
                lastFourDigits
              }
            }
          }
        }
      }
    }
  }
`

export const FOREX_POSITIONS = gql`
  query forexPositions($id: Int!, $status: TradingPositionStatus!, $limit: Int!, $offset: Int!) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            forexPositions(tradingPositionStatus: $status, limit: $limit, offset: $offset) {
              ticket
              symbol
              command
              volume
              openTime
              openRate
              closeTime
              closeRate
              profit
              stopLoss
              takeProfit
              commission
              swaps
              investment
              leverage
            }
          }
        }
      }
    }
  }
`

export const FOREX_POSITIONS_COUNT = gql`
  query forexPositionsCount($id: Int!, $status: TradingPositionStatus!) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            forexPositionsCount(tradingPositionStatus: $status)
          }
        }
      }
    }
  }
`

export const FOREX_ACCOUNT_SETTINGS = gql`
  query forexAcountSetting($id: Int) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            id
            remoteId
            leverage
            allowedLeverages
            performanceFee
            provideCopyTrading
            showProviderStats
            provideCopyTradingStartDate
            hasOpenPositions
            accountSubtype
            isReadOnly
            isViewOnly
            isArchived
            metadata
          }
          ... on BaseAccount{
            accountName
            isDemo
            passwordProtectedStrategies
          }
          ... on BaseAccount{
            accountName
            remoteId
          }
        }
        canEditLeverage
        company
      }
    }
  }
`

export const ACCOUNTS_TYPES_QUERY = gql`
query {
  viewer {
    ... on Client {
      accounts(orderBy: createdAt, hidden: false) {
        __typename
      }
    }
  }
}
`
export const FIND_CLIENT_QUERY = gql`
  query FindClient($clientId: Int, $remoteId: Int, $email: String, $type: ClientSearchType!){
    findClient(clientId: $clientId, remoteId: $remoteId, email: $email, type: $type) {
      firstName
      clientId
      lastName
      email
      accountId
      remoteId
  }
}
`
export const VPS_SUBSCRIPTIONS_QUERY = gql`
  query {
    viewer {
      ... on Client {
        manualVpsPlan
        vpsSubscriptions {
          id
          type
          status
          planName
          serviceId
          billingCycle
          params
          vms
          createdAt
          activatedAt
          nextPaymentDate
          isActive
          isExpired
          lastPaymentFailed
          failedPaymentDate
          billingInfo {
            fullName
            address1
            zip
            cardType
            month
            year
            lastFourDigits
          }
          meta
        }
      }
    }
  }
`

export const SPOA_AUTHORIZATION_QUERY = gql `
    ${SPOA_FIELDS_FRAGMENT}
    query {
      viewer {
        ... on Client
        { ...SpoaFields }
      }
    }`

export const THUNDERXPAY_DEPOSIT_OPTIONS_QUERY = gql`
query GetThunderxPayDepositOptions($country: String!) {
  getThunderxPayOptions(country: $country) {
    options
  }
}
`

export const DYNAMIC_WITHDRAWAL_FIELDS_QUERY = gql`
  query DynamicWithdrawalFields($providerName: String!) {
    dynamicWithdrawalFields(vendorName: $providerName) {
      fields
    }
  }
`

export const CONFIG_QUERY = gql`
  query {
    config {
      minimumDepositDefault
      textToSpeechVerificationEnabled
      failedAppropTestTrainingPeriod
      vpsDunningManagementDays
      maximumAccountEquity
    }
  }
`

export const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethods($depositId: Int) {
    viewer {
      ... on Client {
        paymentMethods(depositId: $depositId) {
          id
          confirmed
          details
          fundingCategory
          pendingUpload{
            allowUpload
            status
          }
        }
      }
    }
  }
`

export const TRANSACTIONS_CLIENT_QUERY = gql`
  query Transactions($offset: Int, $limit: Int) {
    viewer {
      ... on Client {
        transactions(offset: $offset, limit: $limit) {
          id
          paymentFields {
            ${Object.keys(PAYMENT_FIELDS).join(' ')}
            withdrawalFee
            withdrawalNetAmount
          }
          currency
          createdAt
          status
          amount
          withdrawalType
          withdrawalRejectionReason
          withdrawalRejectionReasonText
          withdrawalPendingReason
          category
          vendor
          transactionType
          depositDetails
          withdrawalQueuePosition
          meta
          showCancelPartialDeposit
          depositPaymentFields{
            bankAccountHolderName,
            iban,
            swiftCode,
            bankName,
            transferToAccount,
          }
          transferAccount {
            memberId
            firstName
            lastName
            id
            remoteId
            type
            accountName
          }
          account {
            __typename
            ... on BaseAccount {
              id
              remoteId
              accountName
            }
          }
        }
        transactionsCount
      }
    }
  }
`

export const ACCOUNT_HAS_OPEN_POSITIONS_QUERY = gql`
  query accountHasOpenPositions($accountId: Int!) {
    viewer {
      ...on Client {
        accounts(id: $accountId) {
          ...on BaseForexAccount {
            hasOpenPositions
          }
        }
      }
    }
  }
`
export const ACKNOWLEDGED_NOTICES_QUERY = gql`
  {
    viewer {
      ... on Client {
        acknowledgedNotices {
          acknowledgementType
          notice {
            id
            createdAt
            type
            title
            template
            values
            content
            isContentHtml
          }
          createdAt
        }
      }
    }
  }
`
export const JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY = gql`
  query GetJMFinancialBankTransferDetails($accountId: Int!) {
      getJMFinancialBankTransferDetails(accountId: $accountId) {
        recipient
        iban
        creditingAccount
        swift
        bank
        address
        city
        country
        currency
      }
  }
`

export const PAYRETAILERS_PAYMENT_METHODS_QUERY = gql`
{
  payretailersPaymentMethods {
    paymentMethodId
    name
    channel
    group
    imageUrl
    country
    currency
  }
}
`

export const REFERRAL_DETAILS_QUERY = gql`
  query referralDetails($platform: String){
    viewer {
      ... on Client {
        linkedPartnerClientId
        kycStatus
        tradingStatus
        referAndEarn
        referralDetails(platform: $platform) {
          link
          referrals {
            id
            referralId
            status
            amountReferrer
            registrationDate
          }
          countReferrals
          rewardsObj {
            claimedRewards
            pendingRewards
          }
          awards
          conditions
          activatedReferralAwards{
            amount
            activatedAt
            claimedAt
          }
        }
      }
    }
  }
`

export const VPS_SERVICE_PASSWORD_QUERY = gql`
query VpsServicePassword($serviceId: Int!) {
  getVpsServicePassword(serviceId: $serviceId) {
        password
      }
}
`

export const HELP2PAY_DEPOSIT_BANKS_QUERY = gql`
  query Help2payDepositBanks($currency: String!) {
    help2PayDepositBanks(currency: $currency)
}
`

export const FAQ_QUERY = gql`
{
  faq {
    faqTitle
    faqLink
    faqContent
  }
}
`

export const TRADING_CENTRAL_LINK = gql`
query TradingCentralLink($page: String) {
  tradingCentralLink(page: $page)
}
`
export const SALES_AGENT_EMAIL_FOR_CALLBACK_SUPPORT = gql`
{
  getSalesAgentEmailForCallbackSupportQuery
}
`
